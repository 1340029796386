import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuProps,
  MenuGroup,
  MenuDivider,
  Button,
  ThemeComponentProps,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react';
import { Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, To } from 'react-router-dom';
import InfoTooltip from '../../atoms/info-tooltip';

interface ContextMenuItemConfigBase {
  key: string;
  label: ReactNode;
  action?: To | (() => unknown);
  tooltip?: ReactNode;
}
interface ContextMenuItemConfigDisabled extends ContextMenuItemConfigBase {
  action?: never;
  tooltip?: ReactNode;
}
interface ContextMenuItemConfigEnabled extends ContextMenuItemConfigBase {
  action?: To | (() => unknown);
  tooltip?: never;
}
export type ContextMenuItemConfig =
  | ContextMenuItemConfigDisabled
  | ContextMenuItemConfigEnabled;
export interface ContextMenuActionGroupItemConfig {
  key: string;
  label: string;
  members: ContextMenuItemConfig[];
}
function isActionGroupItem(
  object: any
): object is ContextMenuActionGroupItemConfig {
  return 'members' in object;
}
export interface ContextMenuOptionGroupItemConfig {
  key: string;
  label?: string;
  options: {
    value: string;
    label: ReactNode;
  }[];
  value: string[];
  onChange: (value: string[]) => void;
}
function isOptionGroupItem(
  object: any
): object is ContextMenuOptionGroupItemConfig {
  return 'options' in object;
}

export interface ContextMenuProps {
  items: (
    | ContextMenuItemConfig
    | ContextMenuActionGroupItemConfig
    | ContextMenuOptionGroupItemConfig
  )[];
  menuProps?: Partial<MenuProps>;
  triggerLabel?: ReactNode;
  triggerButtonProps?: Partial<Parameters<typeof Button>[0]>;
  size?: ThemeComponentProps['size'];
  menuFooter?: ReactNode;
  isLoading?: boolean;
}

export default function ContextMenu({
  items,
  menuProps,
  triggerLabel,
  triggerButtonProps,
  size = 'sm',
  menuFooter,
  isLoading,
}: ContextMenuProps) {
  return (
    <Menu isLazy strategy="fixed" {...(menuProps ?? {})}>
      <MenuButton
        display="flex"
        size={size}
        as={Button}
        variant="outline"
        rightIcon={<ChevronDownIcon />}
        isLoading={isLoading}
        {...(triggerButtonProps ?? {})}
      >
        {triggerLabel === undefined ? (
          <FormattedMessage defaultMessage="Options" id="NDV5Mq" />
        ) : (
          triggerLabel
        )}
      </MenuButton>
      <MenuList zIndex={1000}>
        {items.map((item, itemIndex) => {
          if (isActionGroupItem(item)) {
            const { key, label, members } = item;
            return (
              <Fragment key={key}>
                {itemIndex > 0 && <MenuDivider />}
                <MenuGroup title={label}>
                  {members.map((member) => (
                    <ContextMenuActionItem
                      key={member.key}
                      item={member}
                      size={size}
                    />
                  ))}
                </MenuGroup>
              </Fragment>
            );
          }
          if (isOptionGroupItem(item)) {
            const { key, label, options, value, onChange } = item;
            return (
              <Fragment key={key}>
                {itemIndex > 0 && <MenuDivider />}
                <MenuOptionGroup
                  title={label}
                  type="checkbox"
                  value={value}
                  onChange={(newValue: string | string[]) => {
                    onChange(
                      typeof newValue === 'string' ? [newValue] : newValue
                    );
                  }}
                >
                  {options.map((option) => (
                    <MenuItemOption
                      key={option.value}
                      value={option.value}
                      icon={<CheckIcon color="green.700" />}
                      fontSize={size}
                    >
                      {option.label}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </Fragment>
            );
          }
          return (
            <ContextMenuActionItem key={item.key} item={item} size={size} />
          );
        })}
        {menuFooter}
      </MenuList>
    </Menu>
  );
}

function ContextMenuActionItem({
  item,
  size,
}: {
  item: ContextMenuItemConfig;
  size: ThemeComponentProps['size'];
}) {
  const { key, label, action, tooltip } = item;

  if (action === undefined) {
    return (
      <InfoTooltip content={tooltip} key={key}>
        <MenuItem fontSize={size} isDisabled>
          {label}
        </MenuItem>
      </InfoTooltip>
    );
  }
  if (typeof action === 'function') {
    return (
      <MenuItem key={key} fontSize={size} onClick={action}>
        {label}
      </MenuItem>
    );
  }
  return (
    <MenuItem key={key} as={Link} fontSize={size} to={action}>
      {label}
    </MenuItem>
  );
}
