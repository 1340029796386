import { getEnvironment } from '../config/envConfig';

type PathFragment = number | string;
export enum PlanInsightType {
  DELAY = 'delay',
  TIME = 'time',
  SPEED = 'speed',
  IMPACT = 'impact',
  COUNT = 'count',
}
export function isPlanInsightType(value: any): value is PlanInsightType {
  return Object.values(PlanInsightType).some(
    (typeValue) => value === typeValue
  );
}

export enum TeamSectionFragment {
  MEMBERS = 'members',
  GUESTS = 'guests',
}

export function pathLogin() {
  return '/login';
}
export function pathForgotPassword() {
  return '/forgotPassword';
}
export function pathResetPassword() {
  return '/resetPassword';
}
export function pathMfaLogin() {
  return '/mfa';
}
export function pathDashboard() {
  return '/dashboard';
}
export function pathSettings() {
  return '/settings';
}
export function pathRouteStatus(routeId: PathFragment = ':routeId') {
  return `/route-status/${routeId}`;
}
export function pathRoutePollStatus(routeId: PathFragment = ':routeId') {
  return `/route-poll-status/${routeId}`;
}
export function pathTeamSettings(
  orgId: PathFragment = ':insightId',
  sectionId: TeamSectionFragment | ':sectionId' = ':sectionId'
) {
  return `/team/${orgId}/${sectionId}`;
}

export function pathLogout() {
  return '/logout';
}
export function pathSites(insightId: PathFragment = ':insightId') {
  return `/sites/${insightId}`;
}
export function pathSitesOverviewLegacy(insightId = ':insightId') {
  return `${pathSites(insightId)}/overview`;
}

export type SitesOverviewTabId =
  | 'sites'
  | 'routes'
  | 'scorecards'
  | 'sites-performance';
export function pathSitesOverview(
  insightId = ':insightId',
  overviewTab: SitesOverviewTabId | ':overviewTab' = ':overviewTab',
  detailId: string | null = ':detailId?'
) {
  return `${pathSites(insightId)}/overview/${overviewTab}${
    detailId === null ? '' : `/${detailId}`
  }`;
}
export function pathSitesEdit(
  insightId: PathFragment = ':insightId',
  siteId?: PathFragment
) {
  return `${pathSites(insightId)}/edit${!siteId ? '' : `?siteId=${siteId}`}`;
}
export function pathSitesView(
  insightId: PathFragment = ':insightId',
  siteId?: PathFragment
) {
  return `${pathSites(insightId)}/view${!siteId ? '' : `?siteId=${siteId}`}`;
}
export function pathSitesPlan(
  insightId: PathFragment = ':insightId',
  planInsightType: ':planInsightType' | PlanInsightType = ':planInsightType'
) {
  return `${pathSites(insightId)}/plan/${planInsightType}`;
}
export function pathSitesPlanLegacy(insightId: PathFragment = ':insightId') {
  return `${pathSites(insightId)}/plan`;
}
export function pathSitesManage(insightId: PathFragment = ':insightId') {
  return `${pathSites(insightId)}/manage`;
}
export function pathSitesCreate(
  insightId: PathFragment = ':insightId',
  siteType: 'project' | 'network' | ':siteType' = ':siteType'
) {
  return `${pathSites(insightId)}/create/${siteType}`;
}

export function pathSiteVmsBoards(
  insightId: PathFragment = ':insightId',
  siteId: PathFragment = ':siteId'
) {
  return `/vms/${insightId}/${siteId}`;
}
export function pathSiteVmsBoardDetails(
  insightId: PathFragment = ':insightId',
  siteId: PathFragment = ':siteId',
  vmsBoardId: PathFragment = ':vmsBoardId'
) {
  return `${pathSiteVmsBoards(insightId, siteId)}/view/${vmsBoardId}`;
}
export function pathSiteVmsBoard(
  insightId: PathFragment = ':insightId',
  siteId: PathFragment = ':siteId',
  vmsConfigId: PathFragment | null = ':vmsConfigId?'
) {
  return `${pathSiteVmsBoards(insightId, siteId)}/board${
    vmsConfigId === null ? '' : `/${vmsConfigId}`
  }`;
}

export function pathImpactAssessmentRequest(
  insightId: PathFragment = ':insightId',
  siteId: PathFragment = ':siteId'
) {
  return `/impact-assessment/${insightId}/${siteId}/request`;
}

export function pathCookies() {
  return '/cookies';
}
export function pathForbidden() {
  return '/forbidden';
}
export function pathOnboarding() {
  return '/onboarding/*';
}
export function pathUnsubscribe() {
  return '/unsubscribe';
}
export function pathScorecard(scorecardId: string) {
  return getEnvironment() === 'production'
    ? `https://scorecard.mooven.com?id=${scorecardId}`
    : `https://scorecard.dev.mooven.com?id=${scorecardId}`;
}
